<template>
    <b-modal id="add-location" hide-header hide-footer centered size="lg">
        <h3>{{ $t('addLocation') }}</h3>
        <form class="location-form" @submit.prevent="handleSubmit">
            <div class="sect">
                <div class="sect-1">
                    <div class="img-upload">
                        <div>
                            <b-form-file
                                multiple
                                name="image"
                                @input="(files) => onFileChange(files, 'image')"
                                :state="Boolean(fileImages)"
                                :placeholder="$t('chooseImageOrDropHere')"
                                :drop-placeholder="$t('dropImageHere')"
                                :browse-text="$t('browse')"
                                accept="image/*"
                            >
                            </b-form-file>
                        </div>
                        <p class="note">
                            {{ $t('note') + ': ' + $t('max5imgLessThan2mb') }}
                        </p>
                        <div class="list-image">
                            <div
                                class="image"
                                v-for="(imageUrl, index) in imagesUrl"
                                :key="index"
                            >
                                <img
                                    class="img-preview"
                                    :src="imageUrl"
                                    :alt="imagesUrl.name"
                                />
                                <button type="button" class="overlay">
                                    <svg
                                        @click="handleRemoveImage(index)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="logo-upload">
                        <div>
                            <b-form-file
                                name="logo"
                                v-model="fileLogo"
                                @input="(files) => onFileChange(files, 'logo')"
                                :state="Boolean(fileLogo)"
                                :placeholder="$t('chooseLogoOrDropHere')"
                                :drop-placeholder="$t('dropImageHere')"
                                :browse-text="$t('browse')"
                                accept="image/*"
                            >
                            </b-form-file>
                            <p class="note">
                                {{ $t('note') + ': ' + $t('lessThan2mb') }}
                            </p>
                        </div>
                        <img
                            class="img-preview"
                            v-if="logoUrl"
                            :src="logoUrl"
                            :alt="logoUrl.name"
                        />
                    </div>
                </div>
                <div class="sect-2">
                    <div>
                        <b-form-input
                            v-model="location.name"
                            :placeholder="$t('enterYourName')"
                        ></b-form-input>
                    </div>

                    <div>
                        <b-form-input
                            type="email"
                            v-model="location.email"
                            :placeholder="$t('enterYourEmail')"
                        ></b-form-input>
                    </div>

                    <div>
                        <b-form-input
                            v-model="location.phone"
                            :placeholder="$t('enterYourPhoneNumber')"
                        ></b-form-input>
                    </div>

                    <div>
                        <b-form-textarea
                            rows="3"
                            max-rows="3"
                            v-model="location.description"
                            :placeholder="$t('enterYourDescription')"
                        >
                        </b-form-textarea>
                        <p
                            :style="
                                location.description.length > 500
                                    ? 'color: red'
                                    : ''
                            "
                            class="limit-text"
                        >
                            {{ location.description.length }} / 500
                        </p>
                    </div>

                    <div>
                        <b-form-select
                            v-if="ListCategory"
                            v-model="location.category_id"
                            :placeholder="$t('chooseYourCategory')"
                        >
                            <b-form-select-option
                                v-if="location.category_id === null"
                                :value="null"
                                >{{
                                    $t('chooseYourCategory')
                                }}</b-form-select-option
                            >
                            <b-form-select-option
                                v-for="category in ListCategory.results"
                                :key="category.id"
                                :value="category.id"
                                >{{ category.name }}</b-form-select-option
                            >
                        </b-form-select>
                    </div>

                    <div>
                        <b-form-input
                            v-model="location.physical_address"
                            :placeholder="$t('enterYourPhysicalAddress')"
                        >
                        </b-form-input>
                    </div>

                    <!-- Province -->
                    <div>
                        <b-form-select
                            v-model="location.province"
                            :placeholder="$t('chooseYourProvince')"
                            @change="handleChangeProvince"
                        >
                            <b-form-select-option
                                v-if="location.province.code === null"
                                :value="{ name: null, code: null }"
                                >{{
                                    $t('chooseYourProvince')
                                }}</b-form-select-option
                            >
                            <b-form-select-option
                                v-for="province in ListProvince"
                                :key="province.code"
                                :value="{
                                    name: province.name,
                                    code: province.code,
                                }"
                                >{{ province.name }}</b-form-select-option
                            >
                        </b-form-select>
                    </div>

                    <!-- District -->
                    <div>
                        <b-form-select
                            v-model="location.district"
                            :placeholder="$t('chooseYourDistrict')"
                            :disabled="
                                location.province.code === null ? true : false
                            "
                            @change="handleChangeDistrict"
                        >
                            <b-form-select-option
                                v-if="location.district.code == null"
                                :value="{ name: null, code: null }"
                            >
                                {{ $t('chooseYourDistrict') }}
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="district in ListDistrict"
                                :key="district.code"
                                :value="{
                                    name: district.name,
                                    code: district.code,
                                }"
                            >
                                {{ district.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>

                    <!-- Ward -->
                    <div>
                        <b-form-select
                            v-model="location.ward"
                            :placeholder="$t('chooseYourWard')"
                            :disabled="
                                location.district.code === null ? true : false
                            "
                        >
                            <b-form-select-option
                                v-if="location.ward.code === null"
                                :value="{ name: null, code: null }"
                            >
                                {{ $t('chooseYourWard') }}
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="ward in ListWard"
                                :key="ward.code"
                                :value="{ name: ward.name, code: ward.code }"
                            >
                                {{ ward.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                </div>
            </div>
            <button class="btn-submit">{{ $t('submit') }}</button>
        </form>
        <button @click="$bvModal.hide('add-location')">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </button>
    </b-modal>
</template>

<script>
export default {
    name: 'AddLocationModal',

    props: {
        ListCategory: [],
        ListProvince: [],
        ListDistrict: [],
        ListWard: [],
    },

    data() {
        return {
            fileImages: [],
            fileLogo: undefined,
            imagesUrl: [],
            logoUrl: undefined,

            location: {
                name: '',
                email: '',
                phone: '',
                description: '',
                images: [],
                logo: '',
                category_id: null,
                physical_address: '',
                province: {
                    name: null,
                    code: null,
                },
                district: {
                    name: null,
                    code: null,
                },
                ward: {
                    name: null,
                    code: null,
                },
            },

            formError: {
                name: '',
                email: '',
                phone: '',
                description: '',
                physical_address: '',
                category: '',
                province: '',
                district: '',
                ward: '',
            },
        };
    },

    methods: {
        onFileChange(files, name) {
            if (name === 'image') {
                if (this.fileImages.length + files.length > 5) {
                    this.$toastr.e('You can only upload 5 images', 'Oops!');
                    return;
                }

                if (files.length > 0) {
                    files.forEach((file) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.location.image = file;
                            this.fileImages.push(file);
                            this.imagesUrl.push(e.target.result);
                        };
                        reader.readAsDataURL(file);
                    });
                }
            } else if (name === 'logo') {
                if (files) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.location.logo = files;
                        this.logoUrl = e.target.result;
                    };
                    reader.readAsDataURL(files);
                }
            }
        },

        handleRemoveImage(index) {
            this.fileImages.splice(index, 1);
            this.imagesUrl.splice(index, 1);
            console.log(this.fileImages);
        },

        handleSubmit() {
            if (this.location.name === '') {
                this.$toastr.e('Name is required', 'Oops!');
                return;
            }

            if (this.location.email === '') {
                this.$toastr.e('Email is required', 'Oops!');
                return;
            }

            if (this.location.phone === '') {
                this.$toastr.e('Phone is required', 'Oops!');
                return;
            }

            if (this.location.description === '') {
                this.$toastr.e('Description is required', 'Oops!');
                return;
            }

            if (this.location.description.length > 500) {
                this.$toastr.e('Description is too long', 'Oops!');
                return;
            }

            if (this.location.physical_address === '') {
                this.$toastr.e('Physical address is required', 'Oops!');
                return;
            }

            if (!this.location.category_id) {
                this.$toastr.e('Category address is required', 'Oops!');
                return;
            }

            if (!this.location.province.code) {
                this.$toastr.e('Province is required', 'Oops!');
                return;
            }

            if (!this.location.district.code) {
                this.$toastr.e('District is required', 'Oops!');
                return;
            }

            if (!this.location.ward.code) {
                this.$toastr.e('Ward is required', 'Oops!');
                return;
            }

            if (this.fileImages.length === 0) {
                console.log(this.location.images);
                this.$toastr.e('Images is required', 'Oops!');
                return;
            }

            if (this.location.logo === '') {
                this.$toastr.e('Logo is required', 'Oops!');
                return;
            }

            const data = {
                ...this.location,
                physical_address: `${this.location.physical_address}, ${this.location.ward.name}, ${this.location.district.name}, ${this.location.province.name}`,
                province: this.location.province.code,
                district: this.location.district.code,
                ward: this.location.ward.code,
                images: this.fileImages,
            };
            this.$store.dispatch('map/onAddLocation', data).then((res) => {
                if (res) {
                    if (res.status) {
                        this.$store.dispatch('map/getMap');
                        this.$bvModal.hide('add-location');
                    }
                }
            });
        },

        handleChangeProvince(event) {
            this.$store.dispatch('map/getDistrict', event.code);
        },

        handleChangeDistrict(event) {
            this.$store.dispatch('map/getWard', event.code);
        },
    },
};
</script>

<style lang="scss" scoped>
.location-form {
    .sect {
        display: flex;
        gap: 32px;
        margin-top: 32px;

        .sect-1 {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 24px;

            .img-upload {
                display: flex;
                flex-direction: column;
                justify-content: start;
                height: 50%;

                .note {
                    font-size: 12px;
                    color: #ff4d4f;
                    margin: 0;
                }

                .img-preview {
                    width: 100%;
                    height: 160px;
                    object-fit: cover;
                    border: 2px solid #107fb9;
                    border-radius: 12px;
                    margin-top: 12px;
                }

                .list-image {
                    height: 100%;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 12px;
                    margin-top: 12px;

                    .image {
                        position: relative;

                        &:hover .overlay {
                            opacity: 1;
                        }

                        img {
                            margin-top: 0;
                            height: 80px;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        font-size: 18px;
                        transition: opacity 0.3s ease;
                        opacity: 0;
                        border-radius: 12px;

                        svg {
                            top: 50% !important;
                            right: 50% !important;
                            transform: translate(50%, -50%) !important;

                            &:hover {
                                transition: all 0.3s ease;
                                color: #ff4d4f;
                            }
                        }
                    }
                }
            }

            .logo-upload {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 50%;
                gap: 12px;

                .note {
                    font-size: 12px;
                    color: #ff4d4f;
                    margin: 0;
                }

                img {
                    margin-top: 0 !important;
                }

                .img-preview {
                    width: 100%;
                    height: 160px;
                    object-fit: cover;
                    border: 2px solid #107fb9;
                    border-radius: 12px;
                    margin-top: 12px;
                }
            }
        }

        .sect-2 {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: space-between;

            .form-control {
                background-color: white;
            }

            div {
                select {
                    cursor: pointer;
                    border-radius: 12px;
                }

                .limit-text {
                    padding-top: 4px;
                    font-size: 12px;
                    margin: 0;
                }
            }
        }
    }

    .btn-submit {
        &:hover {
            background-color: #107eb9b6;
        }

        width: 100%;
        margin-top: 16px;
        background-color: #107fb9;
        transition: all 0.3s ease-in-out;
        color: white;
        padding: 6px 12px;
        border-radius: 12px;
    }

    @media (max-width: 640px) {
        .sect {
            flex-direction: column;
            gap: 16px;

            .sect-1 {
                width: 100%;
                gap: 16px;
            }

            .sect-2 {
                width: 100%;
            }
        }
    }
}
</style>
