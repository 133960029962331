<!-- <template>
    <div>
        <div
            id="myMap"
            style="margin-left: auto; position: relative; height: 768px"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CustomMap',
    props: {
        poi: {
            type: Object,
            required: true,
        },
        pois: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            map: null,
            camera: null,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        ComputedPois() {
            const myArray = this.pois.map((el) => {
                return [el.longitude, el.latitude];
            });
            return myArray;
        },
    },
    methods: {
        initMap() {
            const script = document.createElement('script');
            script.src =
                'https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.js';
            script.onload = () => {
                const map = new atlas.Map('myMap', {
                    center: [this.poi.longitude, this.poi.latitude],
                    zoom: 16,
                    enableAccessibility: false,
                    view: 'Auto',
                    authOptions: {
                        authType: 'subscriptionKey',
                        subscriptionKey:
                            'EgHzHkRthRqctbvGMnFQHes3NN8DAHpVhssjheTZZkZheL2DScXdJQQJ99AIACYeBjFK9zUBAAAgAZMPJ1qS', // Replace with your subscription key
                    },
                });
                map.events.add('ready', () => {
                    // Load the custom image icon into the map resources.
                    map.imageSprite
                        .add(
                            'my-custom-icon',
                            require('@/assets/images/logo/logo.png'),
                        )
                        .then(() => {
                            // Create a data source and add it to the map.
                            const datasource = new atlas.source.DataSource();
                            map.sources.add(datasource);

                            // Create a point feature and add it to the data source.
                            datasource.add(
                                new atlas.data.FeatureCollection(
                                    this.ComputedPois.map((el) => {
                                        return new atlas.data.Point(el);
                                    }),
                                ),
                            );

                            // Add a layer for rendering point data as symbols.
                            map.layers.add(
                                new atlas.layer.SymbolLayer(datasource, null, {
                                    iconOptions: {
                                        image: 'my-custom-icon', // Use the custom icon loaded earlier.
                                        size: 0.2, // Scale the size of the icon.
                                    },
                                    // textOptions: {
                                    //     textField: 'Enterprise Node',
                                    //     offset: [0, 1], // Offset the text above the icon.
                                    // },
                                }),
                            );
                        });
                });
                this.map = map;
                this.camera = this.map.getCamera();
            };
            document.head.appendChild(script);
        },
    },

    mounted() {
        this.initMap();
        console.log('test map: ', this.poi);
    },
};
</script>

<style scoped>
/* Optional custom styles */
</style> -->

<template>
    <div>
        <!-- <div ref="map" class="map"></div> -->
        <iframe class="map" :src="myMap" width="100%" height="768" style="border: 0" loading="lazy"></iframe>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            map: null,
            marker: null,
            zoomLevel: 14
        };
    },

    props: {
        poi: {
            type: Object,
            required: true,
        },
        pois: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        ComputedPois() {
            const myArray = this.pois.map((el) => {
                return [el.longitude, el.latitude];
            });
            return myArray;
        },

        myMap() {
            return `https://www.google.com/maps?q=${this.poi.latitude},${this.poi.longitude}&hl=es;z=${this.zoomLevel}&output=embed`;
        },
    },

    methods: {
        loadGoogleMapsScript() {
            if (typeof google !== 'undefined') {
                this.initMap();
                return;
            }

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyuGsV0yCPUSv2QeB0Y_sYt8FqeGt4eOc&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = this.initMap;
            document.head.appendChild(script);
        },
        initMap() {
            // Initialize the Google Map when the script has loaded
            this.map = new google.maps.Map(this.$refs.map, {
                center: { lat: this.poi.latitude, lng: this.poi.longitude }, // Center map on a specific location, e.g., San Francisco
                zoom: 19,
            });

            // Optional: Add a marker to the map
            this.marker = new google.maps.Marker({
                position: { lat: this.poi.latitude, lng: this.poi.longitude },
                map: this.map,
                title: 'Current Position',
            });
        },
    },

    mounted() {
        // this.loadGoogleMapsScript();
    },
};
</script>

<style scoped>
div {
    height: 100%;

    .map {
        width: 100%;
        height: 100%;
        border-radius: 22px;

        @media (max-width: 992px) {
            height: 700px;
        }

        @media (max-width: 670px) {
            height: 600px;
        }

        @media (max-width: 640px) {
            height: 500px;
        }
    }
}
</style>
