<template>
    <div>
        <div class="contact" @click="openChat(item.refId, item.dateReport)">
            {{ $t('contactNow') }}
            <img src="@/assets/images/icons/chat.png" alt="img" />
        </div>

        <b-modal
            content-class="support-chat"
            dialog-class="custom-dialog-chat"
            :id="`support-chat ${item.refId}`"
            hide-footer
            hide-header
            centered
        >
            <div class="title">
                <button
                    class="close"
                    @click="$bvModal.hide(`support-chat ${item.refId}`)"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
                <div class="ref-id">{{ myRefId }}</div>
                <div class="date">{{ new Date(myDate).toLocaleString() }}</div>
            </div>

            <div class="chat-wrap" v-if="messageList">
                <div v-for="(item, index) in reversedList" :key="index">
                    <!-- User -->
                    <div
                        class="user"
                        v-if="!item.managerId && item.text && item.text !== ''"
                    >
                        <div>
                            <div class="info">
                                <div class="avatar">
                                    <img
                                        src="@/assets/images/icons/client.png"
                                        alt="avatar"
                                    />
                                </div>
                                <div class="chat">
                                    {{ item.text }}
                                </div>
                            </div>
                            <div class="timestamp">
                                {{ getDateTime3(item.createdAt) }}
                            </div>
                        </div>
                        <div
                            class="image-container"
                            v-if="item.file.length > 0"
                        >
                            <div v-for="file in item.file" :key="file">
                                <img
                                    @click="$bvModal.show('img-preview' + file)"
                                    v-if="
                                        file.endsWith('.jpg') ||
                                        file.endsWith('.jpeg') ||
                                        file.endsWith('.png') ||
                                        file.endsWith('.gif')
                                    "
                                    :src="file"
                                />
                                <video
                                    @click="
                                        $bvModal.show('video-preview' + file)
                                    "
                                    class="size-[80px]"
                                    v-else-if="
                                        file.endsWith('.mp4') ||
                                        file.endsWith('.webm') ||
                                        file.endsWith('.ogg')
                                    "
                                    :src="file"
                                ></video>
                                <b-modal
                                    content-class="custom-class-modal"
                                    :id="'img-preview' + file"
                                    hide-footer
                                    hide-header
                                    centered
                                >
                                    <img :src="file" alt="img" />
                                </b-modal>
                                <b-modal
                                    content-class="custom-class-modal"
                                    :id="'video-preview' + file"
                                    hide-footer
                                    hide-header
                                    centered
                                >
                                    <video :src="file" alt="img"></video>
                                </b-modal>
                            </div>
                        </div>
                    </div>

                    <!-- Admin -->
                    <div
                        v-if="item.managerId && item.text && item.text !== ''"
                        class="admin"
                    >
                        <div class="info">
                            <div class="avatar">
                                <img
                                    src="@/assets/images/icons/admin.png"
                                    alt=""
                                />
                            </div>
                            <div class="chat">
                                {{ item.text }}
                            </div>
                        </div>
                        <div class="timestamp">
                            {{ getDateTime3(item.createdAt) }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>Send message...</div>

            <div
                class="footer"
                v-if="item.status !== 'rejected' && item.status !== 'approved'"
            >
                <div class="thumbnails" v-if="videosChatUrls.length">
                    <ul class="row w-100 list-unstyled">
                        <li
                            v-for="(file, index) in videosChatUrls"
                            :key="index"
                            class="col-auto"
                        >
                            <div class="position-relative">
                                <img
                                    :src="file.url"
                                    alt="Image preview"
                                    class="w-100 h-100 object-cover"
                                    style="max-width: 100px; max-height: 100px"
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    class="delete position-absolute bg-danger text-white rounded-circle p-1"
                                    @click="removeVideo(file)"
                                    style="
                                        top: -0.5rem;
                                        right: -0.5rem;
                                        width: 1.5rem;
                                        height: 1.5rem;
                                    "
                                >
                                    <path
                                        fill="currentColor"
                                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                                    />
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="thumbnails" v-if="filesChatUrls.length">
                    <ul class="row w-100 list-unstyled">
                        <li
                            v-for="(file, index) in filesChatUrls"
                            :key="index"
                            class="col-auto"
                        >
                            <div class="position-relative">
                                <img
                                    :src="file.url"
                                    alt="Image preview"
                                    class="w-100 h-100 object-cover"
                                    style="max-width: 100px; max-height: 100px"
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    class="delete position-absolute bg-danger text-white rounded-circle p-1"
                                    @click="removeImage(index)"
                                    style="
                                        top: -0.5rem;
                                        right: -0.5rem;
                                        width: 1.5rem;
                                        height: 1.5rem;
                                    "
                                >
                                    <path
                                        fill="currentColor"
                                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                                    />
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>

                <form @submit.prevent="sendMessage(myRefId)" class="input-chat">
                    <input
                        type="text"
                        v-model="textInput"
                        :placeholder="$t('writeMessage')"
                    />
                    <div class="icon-upload">
                        <label for="upload">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="4"
                                    d="M24.707 9.565L9.858 24.415a9 9 0 0 0 0 12.727v0a9 9 0 0 0 12.728 0l17.678-17.677a6 6 0 0 0 0-8.486v0a6 6 0 0 0-8.486 0L14.101 28.657a3 3 0 0 0 0 4.243v0a3 3 0 0 0 4.242 0l14.85-14.85"
                                />
                            </svg>
                        </label>
                        <input
                            id="upload"
                            type="file"
                            @change="handleFileChange"
                            multiple
                        />
                    </div>
                    <button>
                        <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_6621_12441)">
                                <path
                                    d="M11.9331 1.06641L6.06641 6.93307"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M11.9323 1.06641L8.19896 11.7331L6.06563 6.93307L1.26562 4.79974L11.9323 1.06641Z"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_6621_12441">
                                    <rect
                                        width="12.8"
                                        height="12.8"
                                        fill="white"
                                        transform="translate(0.199219)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        item: null,
    },

    data() {
        return {
            videos: [],
            fileList: [],
            textInput: null,
            myRefId: null,
            messageList: [],
        };
    },

    computed: {
        reversedList() {
            if (this.messageList) {
                return [...this.messageList].reverse();
            }

            return [];
        },

        filesChatUrls() {
            return this.fileList.map((file) => ({
                name: file.name,
                url: URL.createObjectURL(file),
            }));
        },

        videosChatUrls() {
            return this.videos.map((file) => ({
                name: file.name,
                url: file.image,
            }));
        },
    },

    methods: {
        ...mapActions('support', ['getMessage']),

        openChat(refId, date) {
            this.myRefId = refId;
            this.myDate = date;
            this.$bvModal.show(`support-chat ${refId}`);
            this.getMessage(refId);
        },

        snapImage(video, url, files) {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext('2d');
            if (!ctx) return null;

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const success = image.length > 100000;

            if (success) {
                this.videos.push({ file: Array.from(files)[0], image });
                URL.revokeObjectURL(url);
            }

            return success;
        },

        handleFileChange(event) {
            const { files } = event.target;
            const type = files[0].type.split('/')[0];

            if (type === 'video') {
                if (this.videos.length + files.length > 1) {
                    this.$toastr.e('You can only upload 1 videos');
                    return;
                }

                const fileReader = new FileReader();

                fileReader.onload = () => {
                    if (!fileReader.result) {
                        console.error('Failed to read file');
                        return;
                    }

                    const blob = new Blob([fileReader.result], {
                        type: files[0].type,
                    });

                    const url = URL.createObjectURL(blob);
                    const video = document.createElement('video');

                    const timeupdate = () => {
                        if (this.snapImage(video, url, files)) {
                            video.removeEventListener('timeupdate', timeupdate);
                            video.pause();
                        }
                    };

                    video.addEventListener('loadeddata', () => {
                        if (this.snapImage(video, url, files)) {
                            video.removeEventListener('timeupdate', timeupdate);
                        }
                    });

                    video.src = url;
                    video.addEventListener('timeupdate', timeupdate);
                    video.preload = 'metadata';
                    video.muted = true;
                    video.playsInline = true;
                    video.play();
                };

                fileReader.readAsArrayBuffer(files[0]);
            }

            if (type === 'image') {
                if (this.filesChatUrls.length + files.length > 5) {
                    this.$toastr.e('You can only upload 5 images');
                    return;
                }

                this.fileList.push(...Array.from(files));
            }
        },

        removeImage(index) {
            URL.revokeObjectURL(this.fileList[index].preview);
            this.fileList.splice(index, 1);
        },

        removeVideo(index) {
            this.videos.splice(index, 1);
        },

        sendMessage(id) {
            const formData = new FormData();

            formData.append('ticketId', id);
            formData.append('text', this.textInput);

            this.fileList.forEach((file) => {
                formData.append('file', file);
            });

            this.videos.forEach((file) => {
                formData.append('file', file.file);
            });

            this.$store.dispatch('support/sendMessage', formData).then(() => {
                this.textInput = null;
                this.videos = [];
                this.fileList = [];
                this.getMessage(this.myRefId);
            });
        },

        async getMessage(refId) {
            const response = await this.$store.dispatch(
                'support/getMessage',
                refId,
            );
            this.messageList = response.results;
        },
    },
};
</script>

<style lang="scss">
.custom-dialog-chat {
    max-width: 95%;

    .support-chat {
        max-width: 731px;

        .modal-body {
            border-radius: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                .ref-id {
                    font-size: 18px;
                    font-weight: 600;
                    color: #0087cc;
                }

                .date {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }

            ::-webkit-scrollbar {
                display: none;
            }

            .chat-wrap {
                font-size: 14px;
                height: 500px;
                overflow-y: scroll;

                .user {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: end;
                    gap: 16px;
                    margin-bottom: 20px;

                    .info {
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 8px;
                    }
                    .timestamp {
                        text-align: right;
                    }
                }

                .admin {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: start;
                    gap: 5px;
                    margin-bottom: 15px;
                    .info {
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                    }
                }

                .chat {
                    text-align: end;
                    border: 1px solid #d5d5d5;
                    border-radius: 12px;
                    padding: 7px 10px;
                }
                .timestamp {
                    font-size: 12px;
                    color: #d5d5d5;
                }
                .image-container {
                    display: flex;
                    gap: 16px;
                    margin-bottom: 16px;

                    div {
                        width: 100px;
                        height: 100px;

                        video {
                            width: 100%;
                            height: 100%;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .footer {
                margin-top: 16px;

                .input-chat {
                    position: relative;

                    input {
                        width: 100%;
                        outline: none;
                        padding: 10px;
                        border: 1px solid #dddddd;
                        border-radius: 5px;
                    }

                    .icon-upload {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 8%;

                        label {
                            cursor: pointer;
                            margin: 0;
                        }

                        input {
                            display: none;
                        }
                    }

                    button {
                        position: absolute;
                        top: 10px;
                        right: 2%;
                        background: #1d75dd;
                        outline: none;
                    }
                }

                .thumbnails {
                    ul {
                        li {
                            div {
                                display: flex;
                                justify-content: center;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    max-width: 100px;
                                    max-height: 100px;
                                    object-fit: cover;
                                }

                                svg {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
