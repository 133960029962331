<script>
export default {
    props: {
        userInput: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            password: '',
            email: null,
            otp: '',
            showPassword: false,
        };
    },

    computed: {
        warningText() {
            let myString = '';
            const lowerCaseLetters = /[a-z]/g;
            const upperCaseLetters = /[A-Z]/g;
            const numbers = /[0-9]/g;
            const special = /[!@#$%^&*]/g;
            let firstTime = true;
            if (this.password.length < 8) {
                myString += 'Password must be at least 8 characters long. ';
            }
            if (!this.password.match(lowerCaseLetters)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += '1 lowercase letter';
                } else {
                    myString += ', 1 lowercase letter';
                }
            }
            if (!this.password.match(upperCaseLetters)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += '1 uppercase letter';
                } else {
                    myString += ', 1 uppercase letter';
                }
            }
            if (!this.password.match(numbers)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += 'a number';
                } else {
                    myString += ', a number';
                }
            }
            if (!this.password.match(special)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += 'a special character';
                } else {
                    myString += ', a special character';
                }
            }
            return myString;
        },
    },

    methods: {
        resetPassword() {
            const isEmail = this.userInput
                .toLowerCase()
                .trim()
                .match(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/);
            const isPhone = this.userInput
                .trim()
                .match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);

            if (isEmail) {
                this.$store
                    .dispatch('auth/resetPassword', {
                        email: this.userInput,
                        password: this.password,
                        otp: Number(this.otp),
                    })
                    .then((res) => {
                        if (res.status) {
                            this.$router.push({
                                name: 'Login',
                                query: { email: this.userInput },
                            });
                        }
                    });

            } else if (isPhone) {
                this.$store
                    .dispatch('auth/resetPassword', {
                        phone: this.userInput,
                        password: this.password,
                        otp: Number(this.otp),
                    })
                    .then((res) => {
                        if (res.status) {
                            this.$router.push({
                                name: 'Login',
                                query: { email: this.userInput },
                            });
                        }
                    });
                console.log('Check phone: ', typeof isPhone);
            } else {
                this.$toastr.e('Invalid input, try again!!', 'Oops!');
            }
        },

        resendOtp() {
            const isEmail = this.userInput
                .toLowerCase()
                .trim()
                .match(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/);
            const isPhone = this.userInput
                .trim()
                .match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
            if (isEmail) {
                this.$store.dispatch('auth/req_verificationOTP', {
                    email: this.userInput,
                    type: 'FORGOT_PASSWORD'
                });
            } else if (isPhone) {
                this.$store.dispatch('auth/sendOTPPhone', {
                    phone: this.userInput,
                    type: 'FORGOT_PASSWORD'
                });
            } else {
                this.$toastr.e('Invalid input, try again!!', 'Oops!');
            }
        },

        toggleShow() {
            this.showPassword = !this.showPassword;
        },
    },
};
</script>

<template>
    <div class="otp-wrapper">
        <form @submit.prevent="resetPassword">
            <div class="img">
                <img src="../../assets/images/logo/logoV3.png" alt="logo" />
            </div>
            <div class="inputs">
                <input type="text" :placeholder="$t('emailOrPhone')" v-model="userInput" autocomplete="email" id="email"
                    required disabled />
                <div class="input-pw">
                    <input :type="showPassword ? 'text' : 'password'" :placeholder="$t('password')" v-model="password"
                        id="password" name="password" required autocomplete="new-password"
                        pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}" />
                    <img v-if="!showPassword" @click="toggleShow" class="eye-open"
                        src="../../assets/images/icons/ph--eye-fill.svg" alt="" />
                    <img v-else @click="toggleShow" class="eye-close"
                        src="../../assets/images/icons/ph--eye-slash-fill.svg" alt="" />
                </div>
                <div v-if="warningText.length > 0" class="note">
                    {{ warningText }}
                </div>
                <div class="input-pw">
                    <input type="text" placeholder="OTP" v-model="otp" id="otp" required />
                    <button type="button" class="get-otp" @click="resendOtp">
                        {{ $t('reSend') }}
                    </button>
                </div>
                <span>
                    <router-link class="router-link" to="/login">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" />
                        </svg>
                        <strong>
                            {{ $t('login') }}
                        </strong>
                    </router-link>
                </span>
            </div>
            <div class="buttons">
                <button>{{ $t('submit') }}</button>
            </div>
        </form>
    </div>
</template>
<style lang="scss">
.otp-wrapper {
    background: linear-gradient(#3e84c6, #1a446b);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;

        .img {
            display: flex;
            justify-content: center;

            >img {
                max-width: 100%;
                width: 200px;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 0 auto;
            margin-top: 1.5rem;

            .note {
                color: #ffc107;
                font-size: 14px;
                padding: 0px 5px;
            }

            .input-pw {
                position: relative;

                input {
                    width: 100%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 24px;
                    cursor: pointer;
                }

                .get-otp {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    bottom: 2px;
                    background-color: #0087cc;
                    border-radius: 6px;
                    color: white;
                    transition: 0.5s;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            input {
                padding: 0 1rem;
                border: 1px solid #e4e4e4;
                border-radius: 8px;
                height: 40px;
                background-color: #6a93b9;
                color: #fff;
            }

            input:focus-visible {
                outline: none;
            }

            input::placeholder {
                color: #fff;
                font-size: 18px;
                font-style: italic;
                font-weight: 400;
            }

            span {
                text-align: right;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5rem;
            gap: 1rem;

            button {
                padding: 8px 0;
                width: 70%;
                border-radius: 72px;
                text-transform: uppercase;
                font-size: 18px;
                transition: 0.5s;

                &:hover {
                    opacity: 0.5;
                }

                &.pending {
                    filter: grayscale(1);
                }
            }

            button:nth-child(1) {
                background: linear-gradient(to right, #1775b8, #91c4e9);
                color: #fff;
                font-weight: 600;
            }

            button:nth-child(2) {
                border: 1px solid #00d1ff;
                color: #00d1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                font-weight: 600;
            }
        }

        span {
            color: #fff;

            .router-link {
                transition: 0.5s;

                &:hover {
                    color: #fff;
                    opacity: 0.5;
                }
            }
        }

        .forgot {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            font-size: 18px;
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        form {
            .inputs {
                input::placeholder {
                    font-size: 16px;
                }

                .input-pw {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
</style>
