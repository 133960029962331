<script>
import { mapGetters } from 'vuex';
import UpdatePassword from '@/components/account/UpdatePassword.vue';
import UpdateAddress from '@/components/account/UpdateAddress.vue';
import MasterXNodeList from '@/components/account/MasterXNodeList.vue';
import UpdateEmail from '@/components/account/UpdateEmail.vue';
import UpdatePhone from '@/components/account/UpdatePhone.vue';
import UpdateSponsor from '@/components/account/UpdateSponsor.vue';
import VerifyOTP from '../../components/account/VerifyOTP.vue';

export default {
    components: {
        UpdatePassword,
        UpdateAddress,
        MasterXNodeList,
        UpdateEmail,
        UpdatePhone,
        UpdateSponsor,
        VerifyOTP
    },
    data() {
        return {
            search: {
                page: 1,
                limit: 10,
            },
            currentPage: 1,
            totalPages: 10,
            isUpdateAvatar: false,
            qrActive: 1,
            isChose: '',
            isActive: 'UpdatePassword',
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            BalanceSystem: 'info/Balance',
            Login: 'history/Login',
            AvailableLangs: 'core/AvailableLangs',
            isOpenModalVerify: 'auth/isOpenModalVerify',
        }),
        NoSponsor() {
            return this.UserInfo.sponsors && this.UserInfo.sponsors.length <= 1;
        },
        NoMasterXNode() {
            return !this.UserInfo.masterId && !this.Master;
        },
        NoUpdateAddress() {
            return !this.UserInfo.address || this.UserInfo.address === '';
        },
        NoEmail() {
            return (
                !this.UserInfo.email ||
                this.UserInfo.email === '' ||
                this.UserInfo.email === null
            );
        },
        NoPhone() {
            return (
                !this.UserInfo.phone ||
                this.UserInfo.phone === '' ||
                this.UserInfo.phone === null
            );
        },
    },
    methods: {
        setLocale() {
            window.$cookies.set('lang', this.$root.$i18n.locale);
        },

        openForm(action) {
            this.isActive = action;
        },
    },
};
</script>
<template>
    <b-container class="wallet-tab">
        <!-- change avatar -->
        <div class="avatar" @mouseenter="isUpdateAvatar = true" @mouseleave="isUpdateAvatar = false">
            <img src="~@/assets/images/logo/beeg.png" width="100%" alt="" />
        </div>
        <!-- information user -->
        <div class="info-user">
            <div class="name">
                <span>ID: {{ UserInfo.refId }}</span>
            </div>
            <div class="phone" v-if="UserInfo.phone && UserInfo.phone.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
                    <path fill="currentColor"
                        d="m221.59 160.3l-47.24-21.17a14 14 0 0 0-13.28 1.22a4.81 4.81 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.91 1.91 0 0 1 0-1.68l21.07-25a6.13 6.13 0 0 0 .42-.58a14 14 0 0 0 1.12-13.27L95.73 34.49a14 14 0 0 0-14.56-8.38A54.24 54.24 0 0 0 34 80c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17a14 14 0 0 0-8.3-14.53M176 210c-71.68 0-130-58.32-130-130a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a4.73 4.73 0 0 0-.43.57a14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 176 210" />
                </svg>
                <span>{{ UserInfo.phone || '' }}</span>
                <svg v-if="!UserInfo.isPhoneVerified" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"
                    viewBox="0 0 256 256" class="warning" v-b-tooltip.hover :title="$t('phoneNotVerify')">
                    <path fill="#FFC107"
                        d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16" />
                </svg>
            </div>
            <div class="email" v-if="UserInfo.email && UserInfo.email.length > 0">
                <img src="~@/assets/images/icons/email.svg" alt="" />
                <span>{{ UserInfo.email || '' }}</span>
                <svg v-if="!UserInfo.isEmailVerified" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"
                    viewBox="0 0 256 256" class="warning" v-b-tooltip.hover :title="$t('emailNotVerify')">
                    <path fill="#FFC107"
                        d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16" />
                </svg>
            </div>
            <div class="address" v-if="UserInfo.address">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                    <rect width="416" height="288" x="48" y="144" fill="none" stroke="currentColor"
                        stroke-linejoin="round" stroke-width="32" rx="48" ry="48" />
                    <path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"
                        d="M411.36 144v-30A50 50 0 0 0 352 64.9L88.64 109.85A50 50 0 0 0 48 159v49" />
                    <path fill="currentColor" d="M368 320a32 32 0 1 1 32-32a32 32 0 0 1-32 32" />
                </svg>
                <span>{{ UserInfo.address || '' }}</span>
            </div>
            <div class="masternode" v-if="UserInfo.masterId">
                Master XNode:
                <span>{{ UserInfo.masterId }}</span>
            </div>
            <div class="sponsor" v-if="UserInfo.sponsors && UserInfo.sponsors.length > 3">
                {{ $t('sponsor') }}:
                <span>{{ UserInfo.sponsors[1] }}</span>
            </div>
            <b-button variant="info" class="button-qrLogin" @click="onGetQR" v-if="false">
                <img src="~@/assets/images/icons/show-qr.svg" alt="" /> Show QR
            </b-button>
        </div>
        <div class="account-action">
            <div class="header">
                <div :class="{ active: isActive === 'UpdatePassword' }" @click="openForm('UpdatePassword')">
                    {{ $t('password') }}
                </div>
                <div v-if="NoSponsor" :class="{ active: isActive === 'UpdateSponsor' }" v-b-modal.update-sponsor>
                    {{ $t('sponsor') }}
                </div>
                <div v-if="NoMasterXNode" :class="{ active: isActive === 'MasterXNode' }"
                    @click="openForm('MasterXNode')">
                    MasterXNode
                </div>
                <div v-if="NoUpdateAddress" :class="{ active: isActive === 'UpdateAddress' }"
                    @click="openForm('UpdateAddress')">
                    {{ $t('address') }}
                </div>
                <div v-if="UserInfo.email === undefined">
                    <UpdateEmail />
                </div>
                <div class="phone" v-if="UserInfo.phone === undefined">
                    <UpdatePhone />
                </div>

                <div class="phone" v-if="UserInfo.phone !== undefined && !UserInfo.isPhoneVerified">
                    <VerifyOTP :value="UserInfo.phone" :input="'phone'" :isOpen="isOpenModalVerify" />
                </div>
                <div class="phone" v-if="UserInfo.email !== undefined && !UserInfo.isEmailVerified">
                    <VerifyOTP :value="UserInfo.email" :input="'email'" :isOpen="isOpenModalVerify" />
                </div>
            </div>
            <div class="form">
                <UpdatePassword v-if="this.isActive === 'UpdatePassword'" />
                <UpdateSponsor v-if="this.isActive === 'UpdateSponsor'" />
                <MasterXNodeList v-if="this.isActive === 'MasterXNode'" />
                <UpdateAddress v-if="this.isActive === 'UpdateAddress'" />
            </div>
        </div>
        <div class="wallet">
            <div class="heading-section mb-4">
                <div class="text">Wallet</div>
            </div>
            <!-- <div class="wallet-address">
                <span class="address">{{ AddressShow }}</span>
            </div> -->
        </div>
        <div class="user-balance">
            <div class="user-balance-box">
                <div class="user-balance-box_title">
                    <img src="@/assets/images/logo/usdt.png" />
                    USDT
                </div>
                <div class="user-balance-box_body">
                    <div class="balance-box-main">
                        <span class="balance-box-main-amount">{{
                            TruncateToDecimals2(UserInfo.USDT, '', 4)
                            }}</span>
                        <span class="balance-box-main-symbol">USDT</span>
                    </div>
                </div>
                <!-- <div class="user-balance-box_footer"></div> -->
            </div>
            <div class="user-balance-box">
                <div class="user-balance-box_title">
                    <img src="@/assets/images/logo/xin.png" />
                    XIN80
                </div>
                <div class="user-balance-box_body">
                    <div class="balance-box-main">
                        <span class="balance-box-main-amount">{{
                            TruncateToDecimals2(UserInfo.xinCoin, '', 4)
                            }}</span>
                        <span class="balance-box-main-symbol">XIN</span>
                    </div>
                    <div class="text-content bound">
                        <span>
                            {{ TruncateToDecimals2(UserInfo.bxinCoin) }}
                        </span>
                        <p>XIN20</p>
                    </div>
                </div>
            </div>
        </div>

    </b-container>
</template>

<style lang="scss" scoped>
.user-balance {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 15px;

    &-box {
        border-radius: 22px;
        border: 1px solid #d8d8d8;
        background: #1478b0;
        flex: 1 1 0%;
        width: 100%;
        max-width: 465px;
        min-width: min(300px, 100%);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;

        &_title {
            color: #fff;
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
            font-size: 22px;
            font-style: italic;
            font-weight: 500;
            line-height: 150%;
            /* 33px */
            letter-spacing: 0.3px;

            img {
                height: 25px;
                width: 25px;
            }
        }

        &_body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .balance-box {
                &-main {
                    color: #00c2ff;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    /* 36px */
                    letter-spacing: 0.3px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-amount {
                        flex: 1 1 0%;
                        text-align: left;
                    }

                    &-symbol {
                        flex: 1 1 0%;
                        text-align: right;
                    }
                }

                &-sub {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-amount {
                        flex: 1 1 0%;
                        text-align: left;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                        /* 27px */
                        letter-spacing: 0.3px;
                        color: #a6a6a6;
                    }

                    &-symbol {
                        flex: 1 1 0%;
                        text-align: right;
                        color: #00c2ff;
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 600;
                        line-height: 150%;
                        /* 36px */
                        letter-spacing: 0.3px;
                    }
                }
            }
        }

        &_footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;

            button {
                background: linear-gradient(50deg,
                        #0087c8 14.98%,
                        #00d1ff 106.07%);
                color: #ffffff;
                max-width: 170px;
                width: 100%;
                height: 42px;
                border-radius: 267px;
                font-weight: 600;
                font-size: 18px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
                border: none;
            }
        }

        .text-content {
            font-size: 1.125em;
            color: #0087cc;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            &.bound {
                color: #31df82;
            }

            >span {
                min-width: 0;
                word-wrap: break-word;
            }

            >div:last-child {
                flex-shrink: 0;
            }
        }
    }
}

.wallet-tab {
    .info-user {
        margin: 20px auto 20px;
        text-align: center;

        .name {
            span {
                color: #656565;
                font-weight: 600;
                font-size: clamp(1em, 2.5vw, 1.5em);
            }
        }

        .phone {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin: 20px 0;

            svg {
                color: #01c2fe;
                font-size: 30px;
            }

            span {
                text-align: center;
                color: #656565;
                font-size: 20px;
                font-weight: 550;
                font-family: 'helvetica';
            }
        }

        .email {
            gap: 8px;
            margin: 20px 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                text-align: center;
                color: #656565;
                font-size: clamp(1em, 2.5vw, 1.25em);
                font-weight: 550;
                font-family: 'helvetica';
                letter-spacing: 1px;
                display: block;
                word-wrap: break-word;
            }
        }

        .address {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            svg {
                color: #3fcafe;
                font-size: 35px;
            }

            span {
                color: #656565;
                font-size: 20px;
                word-break: break-word;
                font-family: 'helvetica';
            }

            @media (max-width: 991px) {
                span {
                    font-size: 16px;
                }
            }
        }

        .masternode {
            color: #656565;
            font-size: clamp(1em, 2.5vw, 1.25em);
            font-weight: 550;
            font-family: 'helvetica';
        }

        .sponsor {
            color: #656565;
            font-size: clamp(1em, 2.5vw, 1.25em);
            font-weight: 550;
            font-family: 'helvetica';
        }
    }

    .avatar {
        width: 150px;
        height: 150px;
        background: #fff;
        border-radius: 50%;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        position: relative;

        img {
            object-fit: contain;
            border-radius: 100vw;
            height: 100%;
            width: 100%;
            border: 2px solid #00c2ff;
            background-color: #032f47;
        }
    }

    .title {
        color: #00c2ff;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 0;
        font-weight: 900;

        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00c2ff;
            position: absolute;
            font-weight: 600;
        }
    }

    .wallet-address {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        .address {
            color: #fff;
            font-size: 1.2em;
            font-weight: bold;
            margin-right: 15px;

            @media (max-width: 991px) {
                text-align: center;
                margin-bottom: 10px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        a {
            margin: 0 5px;
            cursor: pointer;
            background: linear-gradient(180deg, #0effff 0%, #124de3 100%);
            color: #fff;

            img {
                height: 1.2em;
                width: auto;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .special-font {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;

        span {
            font-family: 'HHBI';
        }

        &:last-child {
            font-size: 16px;
        }
    }

    .activity-list {
        margin-top: 20px;
    }

    @media (max-width: 991px) {
        .title {
            margin: 25px auto;
        }

        .wallet-address {
            justify-content: center;
            margin-bottom: 15px;
        }

        .balance-game {
            justify-content: center;
        }
    }
}

.wallet {
    margin-top: 2rem;
}

.account-action {
    padding: 20px;
    border-radius: 20px;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.5rem;
    filter: drop-shadow(0 0px 1px #494848);
    max-width: 700px;

    .header {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        color: #0087c8;

        >div {
            border-radius: 12px;
            font-size: 14px;
            padding: 8px 10px;
            font-weight: 500;
            cursor: pointer;
        }
    }

    @media (max-width: 414px) {
        .header {
            gap: 5px;
        }
    }
}

.active {
    background: rgb(227 235 245);
}
</style>
