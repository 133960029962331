<template>
    <div>
        <div class="table-container">
            <h3>{{ $t('myEsim') }}</h3>
            <div v-if="EsimHistory && EsimHistory.results.length > 0">
                <b-table
                    class="nft-table"
                    responsive
                    striped
                    hover
                    :items="EsimHistory.results"
                    :fields="fields"
                >
                    <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>
                    <template #cell(createdAt)="{ value }">
                        {{ new Date(value).toLocaleString() }}
                    </template>
                    <template #cell(capacity)="{ value }">
                        {{ value }} Mb
                    </template>
                    <template #cell(type)="{ value }">
                        <div v-if="value === 'local'">{{ $t('local') }}</div>
                        <div v-else-if="value === 'regional'">
                            {{ $t('regional') }}
                        </div>
                        <div v-else-if="value === 'global'">
                            {{ $t('global') }}
                        </div>
                    </template>
                    <template #cell(qrCodeURL)="{ value }">
                        <b-button class="btn-info" @click="openQR(value)">
                            {{ $t('activeEsim') }}
                        </b-button>
                    </template>
                    <template #cell(expired_at)="{ value }">
                        {{ new Date(value).toLocaleString() }}
                    </template>
                    <template #cell(status)="{ value }">
                        <div v-if="value === 'ACTIVE'">
                            <strong class="text-active">Active</strong>
                        </div>
                        <div v-else-if="value === 'NOT_ACTIVE'">
                            <strong class="text-warning">Pending</strong>
                        </div>
                    </template>
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                </b-table>
            </div>
            <div v-else>
                <b-table class="nft-table" responsive striped hover show-empty>
                    <template #empty>
                        <div class="no-data">{{ $t('noData') }}</div>
                    </template>
                </b-table>
            </div>
            <Paginate
                v-if="EsimHistory"
                @current="onChangePage"
                :totalPages="EsimHistory.totalPages"
            />
        </div>
        <b-modal
            id="default-nft"
            modal-class="default-nft"
            centered
            hide-header
            hide-footer
        >
            <h3>Default X-NFT</h3>
            <div class="content">
                <div>Are you sure set default this number ?</div>
                <div>
                    By set default to this number, your Esim will bind to this
                    number when you first get it.
                </div>
            </div>
            <div class="buttons">
                <button @click="onSetDefaultNFT(selectedId)">
                    {{ $t('confirm') }}
                </button>
                <button @click="$bvModal.hide('default-nft')">
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close"
                @click="$bvModal.hide('default-nft')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
        <b-modal id="active-esim" hide-header hide-footer centered>
            <img :src="selectedQR" alt="" />
        </b-modal>
    </div>
</template>

<script>
import Paginate from '@/components/shared/Paginate.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            fields: [
                {
                    key: 'orderId',
                    label: 'orderId',
                },
                {
                    key: 'type',
                    label: 'type',
                },
                {
                    key: 'capacity',
                    label: 'Capacity',
                },
                {
                    key: 'createdAt',
                    label: 'createdAt',
                },
                {
                    key: 'expired_at',
                    label: 'expiredAt',
                },
                {
                    key: 'qrCodeURL',
                    label: 'activate',
                },
                {
                    key: 'status',
                    label: 'status',
                },
            ],
            selectedId: null,
            selectedQR: null,
            page: 1,
            limit: 10,
            sortBy: 'createdAt:desc',
        };
    },

    computed: {
        ...mapGetters({
            EsimHistory: 'nft/EsimHistory',
        }),
    },

    methods: {
        onChangePage(payload) {
            this.page = payload;
            this.reqList();
        },

        onSetDefaultNFT(input) {
            this.$store.dispatch('nft/setDefaultNFT', input);
        },

        openDefaultNFT(input) {
            this.selectedId = input;
            this.$bvModal.show('default-nft');
            console.log('Received value: ', input);
        },

        openQR(input) {
            this.selectedQR = input;
            this.$bvModal.show('active-esim');
        },

        reqList() {
            this.$store.dispatch('nft/getEsimHistory', {
                page: this.page,
                sortBy: this.sortBy,
                limit: this.limit,
            });
        },
    },

    mounted() {
        this.reqList();
    },

    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'nft/BUY_ESIM_SUCCESS') {
                this.reqList();
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.table-container {
    background: #1478b0;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    padding: 1.5rem 0.5rem;
    margin-top: 1rem;
    color: #fff;

    @media (min-width: 768px) {
        padding: 2rem;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    table {
        @media (max-width: 769px) {
            overflow-x: auto;
        }
    }

    .text-active {
        color: #78dc78;
    }

    .btn-info {
        color: #fff;
    }

    .no-data {
        font-size: 30px;
        text-align: center;
        font-weight: 600;
    }
}

.default-nft {
    h3 {
        color: #0087cc;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
    }

    .nft-selectedNum {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 1rem 0;

        div:nth-child(1) {
            font-size: 18px;
        }

        div:nth-child(2) {
            font-size: 20px;
            font-weight: 600;
            color: #0087cc;
        }
    }

    .content {
        margin: 1rem 0;

        div:nth-child(1) {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 1rem;
        }

        div:nth-child(2) {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }
    }
    .buttons {
        display: flex;
        justify-content: space-evenly;
        gap: 15px;

        button {
            max-width: 200px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;

            &:nth-child(1) {
                background-image: linear-gradient(
                    to right,
                    #075d9b,
                    #1775b8,
                    #91c4e9,
                    #d2e9fa
                );
                background-position: center;
                background-size: 200%;

                &:hover {
                    background-position: 0%;
                }
            }

            &:nth-child(2) {
                color: #0087cc;
                border: 1px solid #0087cc;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        outline: none;
    }

    @media (max-width: 414px) {
        h3 {
            font-size: 20px;
        }

        .nft-selectedNum {
            div:nth-child(1) {
                font-size: 14px;
            }

            div:nth-child(2) {
                font-size: 16px;
            }
        }

        .buttons {
            button {
                font-size: 14px;
            }
        }
    }
}
</style>

<style lang="scss">
.nft-table {
    th {
        white-space: nowrap;
    }
    tbody {
        tr:hover {
            color: inherit;
        }
    }
    .status {
        background: #dc2626;
        padding: 2px 8px;
        border-radius: 6px;
        font-size: 16px;
        svg {
            vertical-align: text-bottom;
        }

        &.active {
            background: #22c55e;
            color: #fff;
        }
    }

    thead,
    tbody {
        color: #fff;
    }
    tbody td {
        vertical-align: middle;
    }
}

.default-nft {
    .modal-content {
        border-radius: 22px;
        .modal-body {
            padding: 2rem 1rem;
            border-radius: 19px;

            @media (max-width: 414px) {
                padding: 1rem;
            }
        }
    }
}

#active-esim {
    .modal-body {
        border-radius: 12px;
        img {
            width: 100%;
        }

        // svg {
        //     font-size: 30px;
        //     color: black;
        // }
    }
}
</style>
