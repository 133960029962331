const axios = require('../axios.config').default;

export default {
    namespaced: true,

    state: () => ({
        categoryList: null,
        addressList: null,
        provinceList: null,
        districtList: null,
        wardList: null,
        checkMap: null,
    }),

    getters: {
        CategoryList: (state) => state.categoryList,
        AddressList: (state) => state.addressList,
        ProvinceList: (state) => state.provinceList,
        DistrictList: (state) => state.districtList,
        WardList: (state) => state.wardList,
        CheckMap: (state) => state.checkMap,
    },

    actions: {
        async getCategoryList({ commit }) {
            return axios
                .get('/map-address/map-address-category')
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_CATEGORY_LIST', res.data);
                    }
                });
        },

        async getAddressList(
            { commit },
            { category_id, provinceId, districtId, query },
        ) {
            const params = {};

            if (query) params.query = query;
            if (category_id) params.category_id = category_id;
            if (provinceId) params.province = provinceId;
            if (districtId) params.district = districtId;

            return axios
                .get('/map-address/map-address', {
                    params,
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_ADDRESS_LIST', res.data);
                    }
                });
        },

        async getProvince({ commit }, input) {
            return axios.get('/map-address/province', input).then((res) => {
                if (res && res.status) {
                    commit('SET_PROVINCE_LIST', res.data);
                }
            });
        },

        async getDistrict({ commit }, id) {
            return axios.get(`/map-address/district/${id}`).then((res) => {
                if (res && res.status) {
                    commit('SET_DISTRICT_LIST', res.data);
                }
            });
        },

        async getWard({ commit }, id) {
            return axios.get(`/map-address/ward/${id}`).then((res) => {
                if (res && res.status) {
                    commit('SET_WARD_LIST', res.data);
                }
            });
        },

        async getMap({ commit }) {
            return axios.get('/map-address/check-map').then((res) => {
                if (res && res.status) {
                    commit('SET_CHECK_MAP', res.data);
                }
            });
        },

        async onAddLocation(_, input) {
            const formData = new FormData();
            formData.append('name', input.name);
            formData.append('email', input.email);
            formData.append('phone', input.phone);
            formData.append('description', input.description);

            input.images.forEach((image) => {
                formData.append('image', image);
            });

            formData.append('logo', input.logo);
            formData.append('category_id', input.category_id);
            formData.append('physical_address', input.physical_address);
            formData.append('province', input.province);
            formData.append('district', input.district);
            formData.append('ward', input.ward);

            return axios
                .post('/map-address/create-map-address', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
        },
    },

    mutations: {
        SET_CATEGORY_LIST(state, data) {
            state.categoryList = data;
        },

        SET_ADDRESS_LIST(state, data) {
            state.addressList = data;
        },

        SET_PROVINCE_LIST(state, data) {
            state.provinceList = data;
        },

        SET_DISTRICT_LIST(state, data) {
            state.districtList = data;
        },

        SET_WARD_LIST(state, data) {
            state.wardList = data;
        },

        SET_CHECK_MAP(state, data) {
            state.checkMap = data;
        },
    },
};
