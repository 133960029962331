<template>
    <div>
        <div v-if="input === 'phone'" @click="$bvModal.show('auth-otp')">
            {{ $t('phone') }}
        </div>
        <div v-else @click="$bvModal.show('auth-otp')">
            {{ $t('email') }}
        </div>

        <b-modal id="auth-otp" dialog-class="supa-modal" hide-footer hide-header centered>
            <div>
                <div class="head">{{ $t('verify') }}</div>

                <form class="myForm" @submit.prevent="submit">
                    <label>
                        {{ $t('OTP') }}
                        <div class="input-box">
                            <b-form-input type="number" v-model="OTP" :placeholder="$t('enterOTP')"></b-form-input>
                            <b-button @click="resend" class="resend" variant="primary">{{ $t('reSend') }}</b-button>
                        </div>
                    </label>

                    <div class="text-center">
                        <button type="submit" class="botan nice-gradient">
                            {{ $t('submit') }}
                        </button>
                    </div>
                </form>
            </div>

            <!-- Close button x -->
            <button class="close" @click="$bvModal.hide('auth-otp')" type="button" style="color: #0087cc">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'VerifyOTP',

    props: {
        value: {
            type: String
        },

        input: {
            type: String
        },

        isOpen: {
            type: Boolean
        }
    },

    data() {
        return {
            OTP: "",
            type_phone: "ACTIVE_PHONE",
            type_email: "ACTIVE_EMAIL"
        };
    },

    methods: {
        submit() {
            if (this.OTP === "") {
                this.$toastr.e('OTP is required', 'Oops!');
                return
            }

            if (this.input === "email") {
                this.$store
                    .dispatch('auth/verify', { OTP: Number(this.OTP), url: "/auth/verify-email" }).then(() => {
                        this.$bvModal.hide('auth-otp')
                        this.$store.dispatch('info/req_getInfo');
                    })
            } else {
                this.$store
                    .dispatch('auth/verify', { OTP: Number(this.OTP), url: "/auth/verify-phone" }).then(() => {
                        this.$bvModal.hide('auth-otp')
                        this.$store.dispatch('info/req_getInfo');
                    })
            }
        },

        resend() {
            if (this.input === "email") {
                this.$store
                    .dispatch('auth/resend', { value: this.value, type: this.type_email, url: "/auth/send-verification-email" })
            } else {
                const converseNumber = this.value.replace("+84", "0")

                this.$store
                    .dispatch('auth/resend', { value: converseNumber, type: this.type_phone, url: "/auth/send-verification-phone" })
            }
        },
    },

    watch: {
        isOpen(newVal) {
            if (newVal) {
                this.$bvModal.show('auth-otp')
            }
        },
    },
}

</script>

<style lang="scss" scoped>
.myForm {
    padding-top: 15px;

    .botan {
        color: white;
        border-radius: 100vw;
        font-weight: 600;
        padding: 10px 30px;
        box-shadow: 1px 1px 6px -2px #000;
    }

    label {
        display: block;
        font-weight: 600;

        .input-box {
            position: relative;

            >input {
                box-shadow: 0px 0px 6px 2px #0000001c;
                border-radius: 6px;
                border: 1px solid #3f3f46;
                background-color: #d4d4d8;
                display: block;
                width: 100%;
                color: #000;
                padding: 4px 10px;
                margin: 5px 0px;
            }

            .resend {
                border-radius: 0 4px 4px 0;
                position: absolute;
                top: 0;
                right: 0;

            }
        }
    }
}

.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 10px;
}
</style>