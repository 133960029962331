<script>
import LineChart from '@/components/trade/Chart.vue';
import History from '@/components/trade/History.vue';
import TradingWidget from '@/components/trade/Trade.vue';

import {
    listCoin,
    tradingList,
    dataChart,
    detailCrypto,
} from '@/store/modules/exchange';

export default {
    components: { LineChart, History, TradingWidget },
    name: 'ExchangeEnergy',
    data() {
        return {
            type: '',
            ListType: [],
            fields: [
                {
                    key: '_id',
                    sortable: false,
                    label: 'Price',
                    class: 'text-center',
                },
                {
                    key: 'totalAmount',
                    label: 'Amount',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'totalPrice',
                    label: 'Total USDT',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'type',
                    label: 'Buy/Sell',
                    sortable: false,
                    class: 'text-center',
                },
            ],
            sort: null,
            options: [
                { value: null, text: 'Select option' },
                { value: 'Buy', text: 'Buy' },
                { value: 'Sell', text: 'Sell' },
            ],

            width: 1920,
            listActive: 'isBuy',
            tabActive: '',
            tabPopup: 'trading',
            // exchange variables
            chart: [],
            listTrading: {
                buy: [],
                sell: [],
            },
            resizeChart: true,
            dataTrade: {},
            refreshRender: true,
            objectPick: {},
        };
    },
    watch: {
        tabActive: {
            handler() {
                this.tabPopup = 'trading';
            },
        },
        type: {
            handler() {
                this.refreshRender = false;
                this.$nextTick(() => {
                    this.refreshRender = true;
                });
                this.listOfTrading(this.coinActive.slug);
                this.getDetail(this.coinActive.id);
                this.getDataChart(this.coinActive.id);
            },
        },
    },
    computed: {
        coinActive() {
            const items = this.ListType.find((item) => item.slug === this.type);
            return items;
        },

        dataTableSB() {
            let list = [];
            if (this.sort === null) {
                list = [
                    ...this.listTrading.buy.map((item) => ({
                        ...item,
                        type: 'Buy',
                    })),
                    ...this.listTrading.sell.map((item) => ({
                        ...item,
                        type: 'Sell',
                    })),
                ];
            } else if (this.sort === 'Buy') {
                list = [
                    ...this.listTrading.buy.map((item) => ({
                        ...item,
                        type: 'Buy',
                    })),
                ];
            } else if (this.sort === 'Sell') {
                list = [
                    ...this.listTrading.sell.map((item) => ({
                        ...item,
                        type: 'Sell',
                    })),
                ];
            }

            return list;
        },
    },
    methods: {
        handleResize() {
            this.width = window.innerWidth;
            if (window.innerWidth <= 991) {
                this.showBuySell = false;
            } else {
                this.showBuySell = true;
            }
            this.resizeChart = false;
            this.$nextTick(() => {
                this.resizeChart = true;
            });
        },
        rowClass(item) {
            if (item.type === 'Buy') return 'row-buy';
            if (item.type === 'Sell') return 'row-sell';
            return '';
        },
        async getInfoExchange() {
            const data = await listCoin();
            if (!data) {
                return;
            }
            if (data?.length >= 1) {
                this.ListType = data;
                this.type = data[0].slug;
                await this.listOfTrading(data[0].slug);
                await this.getDetail(data[0].id);
                await this.getDataChart(data[0].id);
            } else {
                // this.ListType = [];
                this.$toastr.e('Error', 'Cannot get list coin');
            }
        },
        async listOfTrading(slug) {
            this.refreshRender = false;
            this.$nextTick(() => {
                this.refreshRender = true;
            });
            const [listOfTrading] = await Promise.all([
                tradingList(slug),
                this.getDetail(this.coinActive.id),
                this.$store.dispatch('info/req_getInfo'),
            ]);
            this.listTrading = listOfTrading;
        },
        async getDetail(id) {
            const data = await detailCrypto(id);
            this.dataTrade = data;
        },
        async getDataChart(id) {
            const data = await dataChart({
                query: null,
                id,
            });
            this.chart = data;
        },
        onPickBuyOrSell(payload) {
            console.log(payload);
            // this.dataForm.type = type;
            // this.dataForm = item;
        },
        onShow(popup) {
            if (this.width <= 480) {
                this.tabActive = popup;
            }
        },
        onPickSell(payload) {
            const { _id, totalAmount, totalPrice } = payload;
            this.objectPick = {
                type: 'sell',
                price: _id,
                total: totalAmount,
                totalPrice,
            };
            this.onShow('isSell');
        },
        onPickBuy(payload) {
            const { _id, totalAmount, totalPrice } = payload;
            this.objectPick = {
                type: 'buy',
                price: _id,
                total: totalAmount,
                totalPrice,
            };
            this.onShow('isBuy');
        },
    },
    mounted() {
        this.handleResize();
        // Public
        this.getInfoExchange();
        // Auth
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 991) {
            this.showBuySell = false;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<template>
    <b-container class="page-trade">
        <div class="box-1">
            <div class="stat-tr">
                <div class="text">Type</div>
                <div class="value">
                    <div class="icon">
                        <div class="xin">
                            <p>XIN</p>
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="xin-image"
                            />
                        </div>
                        <div class="xin">
                            <p>USDT</p>
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="usdt-image"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="stat-tr">
                <div class="text">Price</div>
                <div class="value price">
                    <p>
                        {{
                            TruncateToDecimals2(
                                dataTrade.currentPrice || 0,
                                '',
                                5,
                            )
                        }}
                    </p>
                    <img src="@/assets/images/logo/usdt.png" alt="usdt-image" />
                </div>
            </div>

            <div class="stat-tr">
                <div class="text">Min Sell Price</div>
                <div class="value sell">
                    <p>
                        {{
                            TruncateToDecimals2(
                                dataTrade.minSellPrice || 0,
                                '',
                                5,
                            )
                        }}
                    </p>
                    <img src="@/assets/images/logo/usdt.png" alt="usdt-image" />
                </div>
            </div>

            <div class="stat-tr">
                <div class="text">Max Buy Price</div>
                <div class="value buy">
                    <p>
                        {{
                            TruncateToDecimals2(
                                dataTrade.maxBuyPrice || 0,
                                '',
                                5,
                            )
                        }}
                    </p>
                    <img src="@/assets/images/logo/usdt.png" alt="usdt-image" />
                </div>
            </div>

            <div class="stat-tr">
                <div class="text">Volume</div>
                <div class="value volume">
                    <p>
                        {{ TruncateToDecimals2(dataTrade.volume || 0, '', 5) }}
                    </p>
                    <img src="@/assets/images/logo/usdt.png" alt="usdt-image" />
                </div>
            </div>

            <!-- <div class="stat-tr">
                <div class="value">
                    {{ TruncateToDecimals2(dataTrade.poolFee || 0, '', 5) }}
                    USDT
                </div>
                <div class="text">Pool Fee</div>
            </div> -->
        </div>
        <div class="box-chart">
            <div class="chart-cn">
                <LineChart v-if="resizeChart && chart" :chartData="chart" />
            </div>

            <div class="table-bs">
                <div class="header-bs">
                    <p>Buy/Sell</p>
                    <div class="sort">
                        <p>Filter by:</p>
                        <b-form-select
                            v-model="sort"
                            :options="options"
                        ></b-form-select>
                    </div>
                </div>
                <div class="container_table">
                    <b-table
                        :fields="fields"
                        striped
                        hover
                        :items="dataTableSB"
                        :tbody-tr-class="rowClass"
                    >
                    </b-table>
                </div>
            </div>

            <div class="trade">
                <TradingWidget
                    v-if="width >= 768 && coinActive && refreshRender"
                    :coin="coinActive"
                    :showHeader="width <= 1400 ? true : false"
                    @getListTrading="listOfTrading(coinActive.slug)"
                    :itemPick="objectPick"
                />
            </div>
        </div>

        <History
            v-if="width >= 481 && coinActive && refreshRender"
            :coin="coinActive"
            :isMobile="width <= 767 ? true : false"
            :listCurrency="ListType"
            @canceled="listOfTrading(coinActive.slug)"
        />
        <div class="gr-show" v-if="width <= 480">
            <div class="button-trade buy" @click="tabActive = 'isBuy'">Buy</div>
            <div class="button-trade sell" @click="tabActive = 'isSell'">
                Sell
            </div>
        </div>
        <transition
            name="slide"
            enter-active-class="animate__animated animate__fadeInUp"
            leave-active-class="animate__animated animate__fadeOutDown"
        >
            <div class="popup-trade" v-if="width <= 480 && tabActive">
                <div class="popup-trade-header">
                    <div class="popup-header-menu">
                        <div
                            class="popup-header-menu-item"
                            :class="{ active: tabPopup == 'trading' }"
                            @click="tabPopup = 'trading'"
                        >
                            {{ tabActive == 'isBuy' ? 'Buying' : 'Selling' }}
                        </div>
                        <div
                            class="popup-header-menu-item"
                            :class="{ active: tabPopup == 'history' }"
                            @click="tabPopup = 'history'"
                        >
                            History
                        </div>
                    </div>
                    <button
                        class="popup-header-close"
                        @click="tabActive = null"
                    >
                        X
                    </button>
                </div>
                <TradingWidget
                    v-if="coinActive && tabPopup == 'trading' && refreshRender"
                    :coin="coinActive"
                    :showHeader="true"
                    :hiddenHeader="true"
                    :tabActive="tabActive"
                    @getListTrading="listOfTrading(coinActive.slug)"
                    :itemPick="objectPick"
                />
                <History
                    :listCurrency="ListType"
                    v-if="tabPopup == 'history' && coinActive && refreshRender"
                    :coin="coinActive"
                    :isMobile="true"
                    @canceled="listOfTrading(coinActive.slug)"
                />
            </div>
        </transition>
    </b-container>
</template>

<style lang="scss">
.page-trade {
    @media (max-width: 991px) {
        width: 100%;
        max-width: calc(100% - 20px);
        padding: 0;
        padding-bottom: 50px;
    }
}

.page-trade {
    .box-1 {
        align-items: center;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        margin-top: 32px;
        margin-bottom: 24px;

        .stat-tr {
            padding: 20px;
            flex: 1;
            border-radius: 24px;
            border: 1px solid #d8d8d8;
            display: flex;
            flex-direction: column;
            gap: 24px;
            background-color: white;

            &.select-1 {
                border-radius: 0;
                background: none;
                border: 0;
            }

            select {
                width: 100%;
                background: #0f172a;
                border-radius: calc(50px * 2 / 3);
                color: #fff;

                font-size: 1.2em;
                max-height: 45px;
                text-align: center;
                border: 1px solid #fff;

                option {
                    background: #0f172a;
                    text-align: left;

                    &:checked {
                        display: none;
                        text-align: center;
                    }
                }
            }

            .text {
                text-align: center;
                font-family: 'Helvetica';
                font-weight: 600;
                font-size: 1rem;
                letter-spacing: 1px;
            }

            .value {
                text-align: center;
                font-weight: 500;
                font-size: 1em;
                line-height: 120%;

                &.price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 0;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                &.sell {
                    color: #ef4444;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 0;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                &.buy {
                    color: #4ade80;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 0;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                &.volume {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 0;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .icon {
                    display: flex;
                    justify-content: center;
                    gap: 16px;

                    div {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        p {
                            font-size: 20px;
                            font-weight: 600;
                            margin: 0;
                        }

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }

        @media (max-width: 1280px) {
            grid-template-columns: repeat(3, 1fr);

            .stat-tr {
                padding: 16px;
            }
        }

        @media (max-width: 640px) {
            grid-template-columns: repeat(2, 1fr);

            .stat-tr {
                gap: 12px;

                .value {
                    gap: 8px;

                    .icon {
                        gap: 8px;

                        .xin {
                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 428px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .box-chart {
        display: grid;
        gap: 15px;

        .chart-cn {
            grid-column: 1/3;
            grid-row: 1/2;
            border: 1px solid #d8d8d8;
            border-radius: 24px;

            .chart {
                min-height: 365px;
                background-color: white !important;
                border-radius: 24px;
                width: 100%;

                :nth-child(1) {
                    width: 100% !important;

                    canvas {
                        width: 100% !important;
                    }
                }
            }
        }

        .table-bs {
            grid-column: 1/3;
            grid-row: 2/3;
            border-radius: 24px;
            border: 1px solid #d8d8d8;
            padding: 32px;
            background-color: white;

            .container_table {
                overflow: auto;

                .table {
                    min-width: 500px;

                    .row-buy {
                        background-color: #34c7591a;
                        color: #34c759;
                    }

                    .row-sell {
                        background-color: #ff3b301a;
                        color: #ff3b30;
                    }
                }
            }

            .header-bs {
                display: flex;
                justify-content: space-between;
                margin-bottom: 32px;
                align-items: center;

                p {
                    font-size: 24px;
                    margin: 0;
                    font-weight: 700;
                }

                .sort {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 12px;

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        margin: 0;
                        white-space: nowrap;
                    }

                    .custom-select {
                        background-color: #f7f7f7;
                    }
                }

                @media (max-width: 428px) {
                    flex-direction: column;
                    gap: 8px;
                }
            }

            @media (max-width: 640px) {
                padding: 8px;
            }
        }

        .trade {
            grid-column: 3/4;
            grid-row: 1/3;
            height: 100%;
        }

        @media (max-width: 1024px) {
            display: flex;
            flex-direction: column;
        }
    }

    .gr-show {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 80px;
        width: 100%;
        z-index: 20;

        .button-trade {
            margin: 10px auto;
            width: 110px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            outline: none !important;
            font-style: italic;
            letter-spacing: 1px;
            font-size: 1.3em;
            font-weight: 600;

            box-shadow: none;
            filter: drop-shadow(2px 2px 1px rgba($color: #000000, $alpha: 0.7));
            transition: 500ms;

            &:focus,
            &:hover {
                transform: scale(0.9);
            }

            &.sell {
                background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
            }

            &.buy {
                background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }

    .popup-trade {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 60px;
        width: 100%;
        height: auto;
        min-height: calc(100% - 60px);
        max-height: 70vh;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.33) 0%,
                rgba(0, 0, 0, 0.33) 100%
            ),
            radial-gradient(
                99.03% 99.03% at 50% 0.97%,
                #005bae 0%,
                rgba(12, 109, 255, 0.82) 47.4%,
                #033059 100%
            );
        backdrop-filter: blur(8px);
        z-index: 900;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px -10px 10px 15px rgba(0, 0, 0, 0.2);

        .popup-trade-header {
            display: flex;
            justify-content: space-between;
            position: relative;

            // &::after {
            //     content: '';
            //     width: calc(100% - 200px);
            //     height: 1px;
            //     background: #fff;
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;
            // }
            .popup-header-menu {
                display: flex;
                justify-content: flex-start;

                &-item {
                    // border-bottom: 1px solid #fff;
                    flex: 1;
                    height: 45px;
                    width: 100px;
                    display: flex;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    border-radius: 0 0 5px 5px;

                    &.active {
                        background: rgba(0, 0, 0, 0.5);

                        // border: 1px solid #fff;
                        border-bottom: 0;
                    }
                }
            }

            .popup-header-close {
                width: 45px;
                height: 45px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;

                color: red;
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
</style>
