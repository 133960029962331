<template>
    <div class="wrap">
        <div class="voucher">
            <div class="content">
                <div class="tit">
                    <span>{{ $t(type) }}</span>
                </div>
                <div class="prize">
                    <img src="@/assets/images/icons/convert.svg" />
                    <div>{{ $t(type + 'Payment') }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-end">
                    <button class="buy" @click="
                        $router.push({
                            name: route,
                        })
                        ">
                        {{ $t('payment') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            route: this.type === "electric" ? "ElectricPayment" : "InternetPayment",
        }
    },

    props: {
        type: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    flex: 1 1 410px;
    max-width: 480px;
    min-width: 0px;

    .voucher {
        display: flex;

        .branding {
            border-radius: 8px;
            aspect-ratio: 1;
            background-color: #a0d3f8;
            // padding: 5px;
            mask-image: radial-gradient(circle at top right,
                    transparent 8px,
                    black 8px),
                radial-gradient(circle at bottom right,
                    transparent 8px,
                    black 8px);
            mask-type: alpha;
            mask-composite: intersect;
            display: flex;
            align-items: center;
            justify-content: center;

            >img {
                width: 108px;

                @media (min-width: 992px) {
                    width: 137px;
                }
            }
        }

        .content {
            border-radius: 8px;
            background-color: #22578a;
            color: white;
            padding: 10px;
            flex: 1;
            mask-image: radial-gradient(circle at top left,
                    transparent 12px,
                    black 12px),
                radial-gradient(circle at bottom left,
                    transparent 12px,
                    black 12px);
            mask-type: alpha;
            mask-composite: intersect;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tit {
                font-size: 14px;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;

                @media (min-width: 992px) {
                    font-size: 18px;
                }
            }

            .buy {
                color: #0087cc;
                background-color: white;
                font-weight: 600;
                border-radius: 6px;
                padding: 2px 35px;
                outline: none;
            }

            .cost {
                font-size: 18px;
                font-weight: 600;
                line-height: 1;

                >span {
                    font-size: 32px;
                }

                @media (min-width: 992px) {
                    >span {
                        font-size: 50px;
                    }
                }
            }

            .prize {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                font-weight: 600;

                >img {
                    width: 20px;
                }

                @media (min-width: 992px) {
                    font-size: 18px;

                    >img {
                        width: 30px;
                    }
                }
            }
        }
    }
}
</style>