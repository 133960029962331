const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        supportList: {
            limit: 0,
            page: 0,
            totalPages: 0,
            totalResults: 0,
            results: [],
        },

        messageList: {
            limit: 0,
            page: 0,
            totalPages: 0,
            totalResults: 0,
            results: [],
        },
    }),

    getters: {
        supportList: (state) => state.supportList,
    },

    actions: {
        async req_listTicket({ commit }, payload) {
            const data = await axios.get('/ticket/get-report', {
                ...payload,
            });

            commit('setSupportList', data.data);
            return data;
        },

        async reqTicket({ commit, state }, payload) {
            const response = await axios.post('/ticket/create', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status && response.data) {
                const { data } = response;
                const updatedResults = [data, ...state.supportList.results];

                const newState = {
                    ...state.supportList,
                    results: updatedResults,
                };

                commit('setSupportList', newState);
                return data;
            } else {
                return response;
            }
        },

        async getMessage(_, id) {
            const response = await axios.get(`/ticket/get-message/${id}`);
            return response.data;
        },

        async sendMessage(_, input) {
            return axios
                .post('/ticket/send-message', input, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    if (res) {
                        console.log('send message: ', res.data);
                    }
                });
        },
    },

    mutations: {
        setSupportList(state, supportList) {
            state.supportList = supportList;
        },
    },
};
